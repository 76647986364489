import {
  CustomLeadData,
  EmploymentAddLead,
  FinanceLead,
  LeadData,
  OrderPartsLead,
  ServiceLeadData,
  ValueYourTradeAddLead,
  VehicleLeadData,
} from "../types/lead";
import { postDataObject } from "./base";

export async function addVehicleLead(dealerId: string, leadData: VehicleLeadData): Promise<LeadData> {
  const API = `${process.env.LEADS_PATH}/${dealerId}/vehicle`;

  return postDataObject<LeadData>(API, leadData);
}

export async function addServiceLead(dealerId: string, leadData: ServiceLeadData): Promise<LeadData> {
  const API = `${process.env.LEADS_PATH}/${dealerId}/service`;

  return postDataObject<LeadData>(API, leadData);
}

export async function addContactLead(dealerId: string, leadData: LeadData): Promise<LeadData> {
  const API = `${process.env.LEADS_PATH}/${dealerId}/contact`;
  return postDataObject<LeadData>(API, leadData);
}

export async function addCustomLead(dealerId: string, leadType: string, leadData: CustomLeadData): Promise<LeadData> {
  const API = `${process.env.LEADS_PATH}/${dealerId}/custom/${leadType}`;
  return postDataObject<LeadData>(API, leadData);
}

export async function addFinanceLead(dealerId: string, leadData: FinanceLead): Promise<FinanceLead> {
  const API = `${process.env.LEADS_PATH}/${dealerId}/finance`;
  return postDataObject<FinanceLead>(API, leadData);
}

export async function addEmploymentLead(dealerId: string, leadData: EmploymentAddLead): Promise<any> {
  const API = `${process.env.LEADS_PATH}/${dealerId}/employment`;
  return postDataObject<any>(API, leadData);
}

export async function addPartsLead(dealerId: string, leadData: OrderPartsLead): Promise<OrderPartsLead> {
  const API = `${process.env.LEADS_PATH}/${dealerId}/parts`;
  return postDataObject<OrderPartsLead>(API, leadData);
}

export async function addValueYouTradeLead(dealerId: string, leadData: ValueYourTradeAddLead): Promise<any> {
  const API = `${process.env.LEADS_PATH}/${dealerId}/tradeIn`;
  return postDataObject(API, leadData);
}
