import { useState, FC, useEffect, useCallback } from "react";
import { FormFieldValidator, FormValidatorDetails } from "../../../utils/formFieldValidator";
import FormField from "./form-field";
import { DropDownOption } from "../../../types/dropdown";
import CustomDropdown from "../custom-dropdown";
import { IFormEventData, sendAnalyticsEvent } from "../../../utils/analitics";

interface IFormDropdownField {
  required: boolean;
  formValidator: FormFieldValidator;
  fieldName: string;
  errorMessage?: string;
  label: string;
  className?: string;
  disabled?: boolean;
  options: DropDownOption[];
  onChange?: (value: string) => void;
  formEventData?: IFormEventData;
  textOnNonExistingValue?: string;
}

const FormDropdownField: FC<IFormDropdownField> = ({
  required,
  formValidator,
  fieldName,
  errorMessage,
  className,
  label,
  disabled,
  options,
  onChange,
  formEventData,
  textOnNonExistingValue,
}) => {
  const value = formValidator.getFieldValue(fieldName);
  const [curValue, setValue] = useState(value);
  const [validated, setValidated] = useState(true);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const validateCallback = useCallback(
    (e: Event) => {
      if (required) {
        if (!curValue || !options.some((item) => item.value === curValue)) {
          (e as CustomEvent<FormValidatorDetails>).detail.validationFailed = true;
          setValidated(false);
          return;
        }
      }

      setValidated(true);
      formValidator.setFieldValue(fieldName, curValue);
    },
    [curValue, fieldName, formValidator, options, required],
  );

  useEffect(() => {
    formValidator.addEventListener("validated", validateCallback);
    return () => {
      formValidator.removeEventListener("validated", validateCallback);
    };
  }, [formValidator, validateCallback]);

  return (
    <FormField
      label={label}
      required={required}
      fieldName={fieldName}
      className={className}
      validated={validated}
      errorMessage={errorMessage}
      hideErrorIcon
    >
      <CustomDropdown
        options={options}
        selectedOption={value}
        onChanged={(newValue) => {
          formValidator.setFieldValue(fieldName, newValue);
          setValue(newValue);
          sendAnalyticsEvent("asc_form_engagement", { comm_status: "engage", ...formEventData });

          if (onChange) {
            onChange(newValue);
          }
          if (!validated) {
            setValidated(true);
          }
        }}
        disabled={disabled}
        error={!validated}
        textOnNonExistingValue={textOnNonExistingValue}
      />
    </FormField>
  );
};

export default FormDropdownField;
